<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form label-width="80px">
        <el-col :span="8">
          <el-form-item label="手机号">
            <el-input v-model="userPhone" placeholder="TODO"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="onQuery">查询</el-button>
          <el-button @click="onQuery">刷新</el-button>
        </el-col>
      </el-form>
    </el-col>

    <!--列表-->
    <el-table :data="orderList" highlight-current-row style="width: 100%">
      <el-table-column prop="id" label="id"></el-table-column>
      <el-table-column prop="orderId" label="orderId"></el-table-column>
      <el-table-column prop="userId" label="user_id"></el-table-column>
      <el-table-column prop="userPhone" label="user_phone"></el-table-column>
      <el-table-column prop="courseId" label="courseId"></el-table-column>
      <el-table-column prop="courseName" label="课程名"></el-table-column>
      <el-table-column prop="createTime" :formatter="formatTime" label="创建时间"></el-table-column>
      <el-table-column prop="expireTime" :formatter="formatExpireTime" label="失效时间"></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" @click="onEditUserOwnCourse(scope.row.id)">修改</el-button>
          <el-button type="danger" size="small" @click="onDeleteUserOwn(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>

<script>
import { userOwnCourseApi } from "@/api/userOwnCourseApi";
import { orderStatusEnum } from "@/util/EnumUtil";
import { alertMessage } from "@/util/alertMessageUtil.js";
import { epochToDateString } from "@/util/TimeUtil";
import msgLogger from "@/util/msgLogger";

export default {
  data() {
    return {
      userPhone: "",
      orderList: [],
      currentPage: 1,
      totalPage: 10,
    };
  },
  mounted() {},
  methods: {
    formatTime: function(row, column) {
      return epochToDateString(row[column.property]);
    },
    formatExpireTime: function(row, column) {
      return epochToDateString(row[column.property]);
    },
    onChangePage(val) {
      this.currentPage = val;
      this.onQuery();
    },
    async getUserId(userPhone) {
      let qUserId = 0;
      let [ret, err] = await userOwnCourseApi.awaitChain(userOwnCourseApi.getAidaUserByPhone(userPhone));
      if (ret && ret.code == 0 && ret.data) {
        qUserId = ret.data.userId;
      } else {
        msgLogger.error("系统出错" + err);
      }
      return qUserId;
    },
    //获取列表
    async onQuery() {
      let qUserId = await this.getUserId(this.userPhone);
      userOwnCourseApi
        .queryByUserId(qUserId)
        .then((ret) => {
          if (ret.code === 0) {
            this.orderList = ret.data;
            this.totalPage = ret.totalPage;
          } else {
            alertMessage(ret.msg, "warning");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onEditUserOwnCourse(id) {
      let path = `/order/user-own-course-add?id=${id}`;
      let routeData = this.$router.resolve({ path: path });
      window.open(routeData.href, "_blank");
    },
    onDeleteUserOwn(id) {
      let msg = "确定删除该项吗？";
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          userOwnCourseApi.deleteById(id).then((ret) => {
            console.log(ret);
            if (ret.code == 0) {
              alertMessage("成功地删除了用户课程", "success");
              this.onQuery();
            } else {
              alertMessage(ret.msg, "warning");
            }
          });
        })
        .catch(() => {});
    },
  },
  watch: {},
};
</script>

<style scoped></style>
